<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row class="bradcrumb">
            <router-link :to="{name: 'home' }" class="root">
                Dashboard
            </router-link>
            /
            <router-link :to="{name: 'admin-master' }" class="root">
                Admin Master
            </router-link>
            <span class="child">/ Frequency </span>
        </v-row>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center">
                <span>Frequency</span>
            </v-card-title>
            <v-card-text>
                <div class="add-section">
                    <div style="text-align: center;">
                        <v-col>
                            <v-form ref="form">
                            <label class="add-text">Add New Frequency</label>
                             <v-text-field class="text" v-model="frequency" outlined dense :rules="[rules.required]" clearable required></v-text-field>
                          
                            <v-btn color="success" @click="addFrequency()">Add</v-btn>
                            </v-form>
                        </v-col>
                    </div>
                </div>

                <template>
                    <div class="text-center">
                        <v-dialog v-model="dialog" width="500">
                            <v-card>
                                <v-card-title class="grey lighten-2">
                                    Edit Frequency
                                </v-card-title>

                                <v-card-text style="padding-bottom: 0px;">
                                    <label class="add-text">Frequency </label>
                                     <v-text-field class="text" v-model="editedFrequency" outlined dense :rules="[rules.required]" clearable required></v-text-field>
                           
                                </v-card-text>

                                <!-- <v-divider></v-divider> -->

                                <div class="text-center">
                                    <v-spacer></v-spacer>
                                    <v-btn class="m-3" color="error" @click="dialog = false">
                                        Close
                                    </v-btn>
                                    <v-btn class="m-3" color="success" @click="saveEdit()">Update</v-btn>
                                </div>
                            </v-card>
                        </v-dialog>
                    </div>
                </template>

                <template v-if="frequency_list!=null">
                    <v-data-table :headers="headers" :items="frequency_list" :search="search" class="elevation-1 nowrap">
                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <v-spacer></v-spacer>
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search in table" single-line hide-details></v-text-field>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.edit="{ item }">
                            <!-- <v-icon v-if="item.edit==true" color="success" class="mr-2">mdi-check-circle-outline</v-icon> -->
                            <v-icon v-if="item" class="mr-2" @click="updateFrequncy(item)">mdi mdi-pencil-box</v-icon>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <!-- <v-btn :loading="edit_btn_load" @click="editItem(item)" class="link" color="info"> <v-icon title="Activate/Deactivate the User Type">mdi-recycle</v-icon></v-btn> -->
                            <v-switch v-model="item.isactive" @change="editItem(item)"></v-switch>
                        </template>
                         <template v-slot:item.delete="{ item }">
                            <v-icon v-if="item" class="mr-2" @click="deleteFrequency(item)"> mdi mdi-delete</v-icon>
                        </template> 
                    </v-data-table>
                </template>
            </v-card-text>
        </v-card>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import axios from "axios";
    import "@/assets/css/color.css"; // css needs to be imported for each dashboard
    export default {
        data: () => ({
            overlay:false,
            snackbar_msg: "",
            snackbar: false,
            color: "",
            org: null,
            dialog: false,
            search: "",
            frequency: null,
            frequency_list:[],
            editedFrequency: null,
            editedFrequencyId: null,
            Isactive:"",
            edit_btn_load: false,
            rules: {
                required: (value) => !!value || "Required.",
            },
            accountStatus_list: null,
            headers: [
                { text: "Sr. No.", value: "srno" },
                { text: "Name", value: "name" },
                { text: "Edit", value: "edit" },
                { text: "Toggle", value: "actions", sortable: false },
                { text: "Delete", value: "delete" },
            ],
        }),
        watch: {},
        methods: {
             addFrequency() {
            //   alert("add: "+this.frequency);
                const data = {
                    frqName: this.frequency,
                };
                if (this.frequency) {
                    axios
                        .post("/admin/saveFrequency", data)
                        .then((res) => {
                            if (res.data.code == "200") {
                                this.showSnackbar("#4CAF50",  res.data.msg);
                               this.onload();
                            } else {
                                this.showSnackbar("#b71c1c", res.data.msg);
                            }
                        })
                        .catch((error) => {
                            this.showSnackbar("#b71c1c", "Something went wrong!!!");
                            console.log(error);
                        })
                        .finally(() => {});
                } else {
                    this.showSnackbar("#b71c1c", "Please enter Account status");
                    this.$refs.form.validate()
                }
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            editItem(item) {
            //    alert(item.Isactive);
                 const data = { 
                        Isactive:item.isactive,
                        id:item.id
                };
                axios
                    .post("/admin/FrequencyIsactive", data)
                    .then((res) => {
                         if (res.data.msg == "200") {
                                this.showSnackbar("#4CAF50",  "Is active successfully");
                               this.onload();
                            }
                        
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong");
                        console.log(error);
                    })
                    .finally(() => {
                        this.edit_btn_load = false;
                        axios;
                    });
            },

            updateFrequncy(item) {
                // alert(item.name);
                this.dialog = true;
                this.editedFrequency = item.name;
                this.editedFrequencyId = item.id;
            },

            saveEdit(item) {
            //    alert(this.editedFrequency);
                const data = {
                    name: this.editedFrequency,
                    id: this.editedFrequencyId,
                };
               
                    axios
                        .post("/admin/editFrequency", data)
                        .then((res) => {
                            if (res.data.code == "200") {
                            // this.sec_mode_list = res.data.user_list;
                            this.showSnackbar("#4CAF50", "frequency updated successfully...");
                            this.onload();
                            this.dialog = false;
                            } else {
                                this.showSnackbar("#b71c1c", res.data.msg);
                            }
                        })
                        .catch((error) => {
                            this.showSnackbar("#b71c1c", "Something went wrong!!!");
                            console.log(error);
                        })
                        .finally(() => {
                            this.edit_btn_load = false;
                            // this.editUserType = null;
                            axios;
                        });
                },
            
            deleteFrequency(item){                
                const data = {            
                     deleteitem: item.id
                };
                axios
                    .post("/admin/deleteFrequency", data)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.showSnackbar("#4CAF50", "Frequency Deleted Successfully  !!");
                            this.onload();
                        }
                         else if(res.data.msg == "exp"){
                           this.showSnackbar("#b71c1c", "Can not be Deleted!!!");
                       }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        window.console.log(error);
                    });
            },

            onload() {
                this.overlay = true
                axios
                    .post("/admin/getFrequency")
                    .then((res) => {
                        this.org = res.data.org;
                        this.frequency_list = res.data.frequency_list;
                        this.overlay = false
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.overlay = false
                    });
            },

           
        },
        mounted() {
            this.onload();
        },
    };
</script>
<style scoped>
    .heading {
        background-color: #3f51b5;
        padding: 0.3rem;
        color: white;
    }
    .v-icon {
        cursor: pointer;
    }
    .text >>> .v-input__slot {
        background: white !important;
        border-color: hsla(190, 99%, 30%, 1) !important;
    }
</style>
